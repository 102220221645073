function Header() {
  return (
    <header style={{ zIndex: 1500 }}>
      <nav>
        <h1>Golf Website</h1>
        <ul>
          <li>Swing Analysis</li>
          <li>Club Use</li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
